import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import getUndef from "../../lib/get-undef";
import { getShop } from "../../__generated__/getShop";
import { getSales_shop } from "../../__generated__/getSales";

const GET_SHOP = gql`
  query getShop {
    shop {
      id
      workTimeStart
      workTimeEnd
      workTimeSet
      primaryDomain {
        holydays
        holydaysText
      }
    }
  }
`;

const useShop = (): any => {
  const { data, loading, error, refetch } = useQuery<getShop>(GET_SHOP);
  if (loading || error) {
    return {};
  }
  return getUndef(() => ({ ...data.shop, refetch })) || {};
};

export default useShop;
